/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import ExternalLink from './ExternalLink';
import TwitterIconPath from '../images/icons/twitter.png';
import LinkedInIconPath from '../images/icons/linkedin.png';
import XingIconPath from '../images/icons/xing.png';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextContainer = styled.div`
  tex-transform: uppercase;
  background: linear-gradient(0deg, #F96639, #F96639), #FFFFFF;
  border-radius: 4px 0px 0px 4px;
  padding: 5px 10px 5px 10px;
  color: #fff;
  vertical-align: center;
  user-select: none;
`;

const Icon = styled.img`
  height: 29px;
  width: 29px;
  cursor: pointer;
  border-radius: ${props => props.last ? '0px 4px 4px 0px;' : '0 0 0 0'}
`;

const ExternalLinkStyled = styled(ExternalLink)`
  height: 29px;
  width: 29px;
`;

const ShareBar = ({
  slug,
  title,
  className
}) => (
  <Container className={className} >
    <TextContainer>
      Share
    </TextContainer>
      <ExternalLinkStyled
        href={`https://twitter.com/intent/tweet/?text=${title}&url=https://bidmatik.com${slug}%2F&via=larsnolden`}
        target="_blank"
      >
        <Icon src={TwitterIconPath} />
      </ExternalLinkStyled>
      <ExternalLinkStyled
        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://bidmatik.com${slug}&title=${title}`}
        target="_blank"
      >
        <Icon src={LinkedInIconPath} />
      </ExternalLinkStyled>
      <ExternalLinkStyled
        href={`https://www.xing.com/app/user?op=share;url=https://bidmatik.com${slug}`}
        target="_blank"
      >
        <Icon src={XingIconPath} />
    </ExternalLinkStyled>
  </Container>
);

export default ShareBar;
